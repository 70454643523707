import Vue from 'vue'
import VueCarousel from 'vue-carousel'
import App from './App.vue'
import Config from './config.js'

/*
| ----------------------------------------------------------------
| Set pelatihan yang ingin dibuatkan Landing Pagenya, Huruf kecil semua
| ----------------------------------------------------------------
*/
var TIPE_PELATIHAN = "swab";

Vue.config.productionTip = false
Vue.prototype.$config = Config[TIPE_PELATIHAN];

Vue.use(VueCarousel);

/*
| ----------------------------------------------------------------
| Here my CSS will be included
| ----------------------------------------------------------------
*/
import './assets/css/main.css'
import 'animate.css'

new Vue({
  render: h => h(App),
}).$mount('#app')
