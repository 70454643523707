export default {
    hiperkes: {
        webConfig: {
            header: {
                title: "Hiperkes (Batch 19) - Intraina",
            },
            body: {
                title: `
                    HIPERKES<br>
                    <span style="font-size: 0.5em; line-height: 0em !important">& KESELAMATAN KERJA</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BAGI PARAMEDIS PERUSAHAAN</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BATCH 19</span><br>
                `,
                titleSize: 2.6,
                description: "",
                date: "22 - 26 Februari 2022",
                dateDescription: `ONLINE VIA ZOOM APP`,
                imageAfterTitle: "https://st.depositphotos.com/1518767/3917/i/600/depositphotos_39179963-stock-photo-male-doctor-with-laptop-at.jpg",
                purpose: [
                    "Pelatihan ini bertujuan untuk Melindungi tenaga kerja atas hak keselamatannya dalam melakukan pekerjaan untuk kesejahteraan hidup dan meningkatan produksi dan produktivitas nasional.",
                    "Serta pelatihan ini Menjamin keselamatan setiap orang lain yang berada di tempat kerja."
                ],
                imageList: [
                    "https://i.postimg.cc/NMLjSmmD/Capture.jpg",
                    "https://i.postimg.cc/66g0QN66/Capture2.jpg",
                    "https://i.postimg.cc/wx72HBpb/Capture3.jpg",
                    "https://i.postimg.cc/23Nj1tJ9/Capture4.jpg"
                ],
                instruktur: `
                    <ul class="list-custom">
                        <li>
                            BALAI HIPERKES SAMARINDA
                        </li>
                        <li>
                            INTRAINA
                        </li>
                    </ul>
                `,
                material: [
                    "Peraturan Perundangan dan Kebijakan Bidang K3",
                    "SMK3, Audit Internal & Manajemen Resiko di Tempat Kerja",
                    "Higiene Industry Faktor Fisik Kimiia, Biologi",
                    "Psikologi Industri",
                    "Ergonomi dan Fisiologi Kerja",
                    "Program Pelayanan Kesehatan Kerja",
                    "Kecelakaan Kerja dan Sistem Pelaporan",
                    "PAK dan Sistem Pelaporan",
                    "Keselamatan Kerja dan APD",
                    "Pengelolaan Makan di Tempat Kerja",
                    "Program Rehabilitasi di Tempat Kerja",
                    "Promosi Kesehatan dan Pencegahan HIV/AIDS di Tempat Kerja",
                    "BPJS Ketenagakerjaan",
                    "P3K di Tempat Kerja",
                    "Tanggap Darurat Kebakaran di Tempat Kerja",
                    "Walk-through Survey ",
                ],
                imageAfterMaterial: "https://www.campussafetymagazine.com/wp-content/uploads/2019/12/nurses-folded-arms.jpg",
                facility: [
                    "Soft File Materi",
                    "T-Shirt",
                    "Sertifikat KEMENAKER RI (Berlaku Seumur Hidup)",
                    "Gratis pengiriman sertifikat",
                    // "Sertifikat dengan SKP DPW PPNI KALTIM",
                ],
                registration: {
                    price: `
                        Rp 1.750.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(Harga Reguler)</span>
                        <hr style="border: 1px solid black">
                        Rp 1.600.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(s.d 10 Februari 2022)</span>
                    `,
                    priceDescription: "Batas registrasi S.D 18 Februari 2022",
                    dateEnd: {
                        year: 2022,
                        month: 2,
                        day: 18,
                    }
                },
                dataAppend: [
                    {
                        title: "Syarat Peserta",
                        content: `
                        <ul style="list-style-type: decimal;" class="list-custom">
                            <li>
                                Perawat / Bidan
                            </li>
                            <li>
                                Mengisi Formulir Pendaftaran
                            </li>
                            <li>
                                Melampirkan Berkas :<br>
                                a. Fotocopy Ijazah Terakhir (Pendidikan Keperawatan / Kebidanan)<br>
                                b. Pas foto 3x4 berwarna latar merah 4 lbr
                            </li>
                        </ul>
                        `
                    },
                ],
            }
        },
        registration: {
            direct_whatsapp: "https://api.whatsapp.com/send?phone=6285845727387&text=Assalamualaikum wr wb kak putra. saya tertarik dengan pelatihan *HIPERKES* mohon informasi lebih lanjut ya",
            direct_formulir: "https://daftar.intraina.id/"
        }
    },
    swab: {
        webConfig: {
            header: {
                title: "Swab (Batch 10) - Intraina",
            },
            body: {
                title: `
                    <span style="font-size: 0.8em; line-height: 0em !important">INHOUSE TRAINING</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">PENGAMBILAN SAMPLE COVID19</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">DENGAN METODE SWAB</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">NASOFARING & OROFARING</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BATCH 10</span><br>
                `,
                titleSize: 2.6,
                description: "",
                date: "Sabtu, 12 Maret 2022",
                dateDescription: `
                    Pkl. 08-00 Selesai <br>
                    <span style="font-weight: bold">Gd. TUK Balai K3 Samarinda</span>
                `,
                imageAfterTitle: "https://www.alumni.ox.ac.uk/sites/default/files/styles/mt_image_large/public/alumni/images/media/covid_19_test_tube_credit_shutterstock.png?itok=27OQ79su",
                purpose: [
                    "- Memberikan pengetahuan kepada Peserta tentang persiapan, pengambilan swab nasofaring, orofaring, dan penyiapan pengiriman sampel COVID-19",
                    "- Memberikan pengalaman praktik kepada peserta tentang pengambilan swab nasofaring, orofaring, dan menyiapkan sampel COVID-19",
                    "- Menyiapkan tenaga kesehatan yang berkompeten dalam pengambilan swab nasofaring dan orofaring pasien terduga COVID-19.",
                ],
                imageList: [
                    "https://intraina.id/assets/images/galeri/1.jpeg",
                    "https://intraina.id/assets/images/galeri/4.jpeg",
                    "https://intraina.id/assets/images/galeri/7.jpeg",
                    "https://intraina.id/assets/images/galeri/6.jpeg"
                ],
                instruktur: `
                    dr. DIDI IRWADI, Sp. Pk
                `,
                material: [
                    "Konsep Covid 19",
                    "Konsep Teknik Pengambilan SWAB Nasofaring & Orofaring",
                    "Praktek Pengambilan SWAB Nasofaring & Orofaring"
                ],
                imageAfterMaterial: "https://images.unsplash.com/photo-1599493758267-c6c884c7071f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8c3dhYnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
                facility: [
                    "Sertifikat dengan Rekomendasi Satgas Covid 19 Kota Samarinda",
                    "E-Materi",
                    "Baju Hazmat / Coverall",
                    "Handscoon",
                    "Set Alat Rapid Antigen",
                    "GoodieBag",
                    "BlockNote & Stylus Pen",
                    "Faceshield",
                    "Masker Kn95",
                    "Lunch & Snack Box"
                ],
                registration: {
                    price: "Rp 900.000",
                    priceDescription: "Batas registrasi S.D 11 Maret 2022",
                    dateEnd: {
                        year: 2022,
                        month: 3,
                        day: 11,
                    }
                },
                dataAppend: null,
            }
        },
        registration: {
            direct_whatsapp: "https://api.whatsapp.com/send?phone=6285845727387&text=Assalamualaikum wr wb kak putra. saya tertarik dengan pelatihan *SWAB* mohon informasi lebih lanjut ya",
            direct_formulir: "https://daftar.intraina.id/"
        }
    },
    btcls: {
        webConfig: {
            header: {
                title: "BTCLS (Batch 44) - Intraina",
            },
            body: {
                title: `
                    <span style="font-size: 0.8em; line-height: 0em !important">BTCLS</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">PLUS SYSTEM CODE BLUE</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BATCH 44</span><br>
                `,
                titleSize: 2.6,
                description: "",
                date: "14 - 18 Febuari 2022",
                dateDescription: `
                    <div style="margin-top: 10px;" />Blended Training<br>
                    <span style="font-weight: bold">
                        Kampus Smart Academy
                        <br>
                        Jl M.T Haryono Samarinda
                    </span>
                `,
                imageAfterTitle: "https://safeguardsafety.net/storage/uploads/courses/advanced-cardiac-life-support-acls601fcee03dce1.jpeg",
                purpose: [
                    "1. Menjadi Bekal dalam Penanganan Pasien Gawat Darurat Trauma dan Kardiovaskular",
                    "2. Lulusan Baru : Salah satu modal untuk melamar pekerjaan di Rumah Sakit / Perusahaan ",
                    "3. BTCLS Menjadi Salah Satu Syarat Untuk Persiapan Akreditasi Rumah Sakit",
                    "4. Sebagai Syarat administrasi dan kompetensi untuk mendaftar TKHI",
                ],
                imageList: [
                    "https://intraina.id/assets/images/galeri/12.jpeg",
                    "https://intraina.id/assets/images/galeri/13.jpeg",
                    "https://intraina.id/assets/images/galeri/14.jpeg",
                    "https://intraina.id/assets/images/galeri/15.jpeg"
                ],
                // instruktur: `
                //     TIM GTC JAKARTA
                // `,
                material: [
                    "Etik dan Aspek Legal Keperawatan Gawat Darurat",
                    "Sistem Penanggulangan gawat darurat terpadu (SPGDT)",
                    "Bantuan Hidup Dasar",
                    "Penilaian Awal (Initial Assesment)",
                    "Penatalaksanaan Pasien dengan gangguan pernafasan dan jalan nafas (airway and Breathing)",
                    "Penatalaksanaan pasien akibat trauma : Kepala dan spinal, thorak dan abdomen, Musculoskeletal dan luka bakar",
                    "Penatalaksanaan pasien dengan gangguan sirkulasi",
                    "Penatalaksanaan Kegawatdaruratan kardiovaskuler",
                    "Penatalaksanaan proses rujukan",
                    "Triage Pasien",
                    "Materi Tambahan : Manajemen System Code Blue"
                ],
                imageAfterMaterial: "https://eg-ts.org/wp-content/uploads/2020/03/MG_6786Qf.png",
                facility: [
                    "Makan Siang & Coffee Break",
                    "T-Shirt",
                    "Sertifikat SKP DPP PPNI",
                    "Training Kit (Modul, Goodiebag, Stylus Pen)",
                    "Ruangan Nyaman dan Ber AC",
                    "Gratis RDT Antigen",
                    "Masker",
                ],
                registration: {
                    price: "Rp 2.000.000",
                    priceDescription: "Batas registrasi S.D 11 Feb 2022",
                    dateEnd: {
                        year: 2022,
                        month: 2,
                        day: 11,
                    }
                },
                dataAppend: [
                    {
                        title: "Syarat Peserta",
                        content: `
                        <ul style="list-style-type: decimal;" class="list-custom">
                            <li>
                                Perawat / Bidan
                            </li>
                            <li>
                                Tidak dalam Keadaan Hamil 
                            </li>
                            <li>
                                Melampirkan Berkas :<br>
                                a. Fotocopy Ijazah Terakhir (Pendidikan Keperawatan / Kebidanan)<br>
                                b. Pas foto 2x3, 3x4 dan 4x6 berwarna latar merah @2 lbr
                            </li>
                            <li>
                                Menunjukkan Bukti Vaksinasi Covid-19
                            </li>
                        </ul>
                        `
                    },
                ],
            }
        },
        registration: {
            direct_whatsapp: "https://api.whatsapp.com/send?phone=6285845727387&text=Assalamualaikum wr wb kak putra. saya tertarik dengan pelatihan *BTCLS* mohon informasi lebih lanjut ya",
            direct_formulir: "https://daftar.intraina.id/"
        }
    },
    woundcare: {
        webConfig: {
            header: {
                title: "Woundcare (Batch 1) - Intraina",
            },
            body: {
                title: `
                    <span style="font-size: 0.8em; line-height: 0em !important">PELATIHAN SERTIFIKASI PERAWAT LUKA</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">CERTIFIED BASIC WOUND CARE NURSE</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BATCH 1</span><br>
                `,
                titleSize: 2.6,
                description: "",
                date: "21 - 24 Maret 2022",
                dateDescription: `
                    <div style="margin-top: 10px;" />PELATIHAN TATAP MUKA<br>
                    <span style="font-weight: bold">Hotel Royal Park Samarinda</span>
                `,
                imageAfterTitle: "http://www.aqfmedical.com/wp-content/uploads/2019/09/aqf-medical-Hydrophilic-Foams-for-Woundcare-body.jpg",
                purpose: [
                    "Setelah mengikuti kegiatan pembelajaran pada bidang Luka pada tingkat dasar diharapkan peserta mampu :",
                    "1. Menguasai dasar pengetahuan anatomi dan fisiologi dan proses penyembuhan luka, faktor yang mendukung penyembuhan dan nutrisi dalam penyembuhan luka",
                    "2. Mampu melakukan pengkajian luka sesuai dengan format dengan benar",
                    "3. Mampu memperhatikan keamanan dan keselamatan klien dalam perawatan luka",
                    "4. Melakukan penilaian diagnose luka / tipe luka dengan menggunakan alat sesuai kompetensi perawatan luka dasar ",
                    "5. Melakukan evaluasi diagnostik luka dan kondisi luka melalui pemeriksaan fisik, sistemik dan pemeriksaan penunjang lainnya sesuai kondisi klinis luka sesuai kompetensi perawatan dasar",
                    "6. Mampu melakukan terapi kompresi pada pasien dengan indikasi luka venous dan atau indikasi terapi kompresi",
                    "7. Mampu membuat perencanaan pemilihan topical terapi berdasarkan penilaian wound bed dan atau karakteristik luka",
                    "8. Mempu menjelaskan penerapan konsep wound bed preparation",
                    "9. Mampu melakukan perawatan luka dengan benar :",
                    "10. Melakukan asuhan keperawatan dasar pada perawatan luka akut dan kronik",
                ],
                imageList: [
                    "https://careasone.com/blog/wp-content/uploads/2020/11/shutterstock_280652831.jpg",
                    "https://www.hartmann.info/en-corp/-/media/disinfection/img/nci/hartmann-woundmanagement-stage-link-one-pager-nci.jpg?h=1100&iar=0&mw=1440&w=1440&rev=66a14611c7224a9bb39367679575bf5e&sc_lang=en-tt&hash=9AD8947395D5E6069CEAB93274F71E79",
                    "https://www.apothecopharmacy.com/wp-content/uploads/2021/05/blog-1-featured-image.jpg",
                    "https://cdn.ps.emap.com/wp-content/uploads/sites/3/2021/06/Wound-care.jpg"
                ],
                // instruktur: `
                //     TIM GTC JAKARTA
                // `,
                material: [
                    "Konsep Dasar Perawatan Luka",
                    "Konsep Nutrisi Dalam Penyembuhan Luka",
                    "Wound Bed Preparation : TIME",
                    "Pengkajian dan Konsep Manajemen Perawatan Luka",
                    "Konsep BIOBURDEN dalam Perawatan Luka",
                    "Askep Luka Akut : Post OP & Trauma",
                    "Askep Luka Akut : Luka Bakar",
                    "Askep Luka Kronik : Pressure Injury",
                    "Askep Luka Kronik : DFU",
                    "Askep Luka Kronik : Luka Kanker",
                    "Praktek Klinik"
                ],
                imageAfterMaterial: "https://www.news-medical.net/image.axd?picture=2018%2F8%2Fshutterstock_367590767_-_wound.jpg",
                facility: [
                    "Sertifikat SKP DPP PPNI",
                    "Sertifikat Keahlian DPP PPNI & DPP INWOCNA",
                    "T-Shirt",
                    "Training Kit",
                    "Modul Pelatihan",
                    "Bahan Praktek Perawatan Luka",
                    "Makan Siang dan Coffee Break"
                ],
                registration: {
                    price: `
                        Rp 3.000.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(Harga Reguler)</span>
                        <hr style="border: 1px solid black">
                        Rp 2.750.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(s.d 5 Maret 2022)</span>
                    `,
                    priceDescription: "Batas registrasi S.D 21 Maret 2022",
                    dateEnd: {
                        year: 2022,
                        month: 3,
                        day: 21,
                    }
                },
                dataAppend: [
                    {
                        title: "Syarat Peserta",
                        content: `
                        <ul style="list-style-type: decimal;" class="list-custom">
                            <li>
                                Perawat
                            </li>
                            <li>
                                Melampirkan Berkas :<br>
                                a. Fotocopy Ijazah Terakhir (Pendidikan Keperawatan)<br>
                                b. Pas foto 3x4 berwarna latar merah @2 lbr
                            </li>
                            <li>
                                Melakukan Booking seat dengan<br>
                                Registrasi senilai Rp. 500.000 transfer Ke mandiri no rek 1490023838990 a.n PT INTRAINA JAYA MANDIRI
                            </li>
                            <li>
                                Menunjukkan Bukti Vaksinasi Covid-19
                            </li>
                        </ul>
                        `
                    },
                ],
            }
        },
        registration: {
            direct_whatsapp: "https://api.whatsapp.com/send?phone=6285845727387&text=Assalamualaikum wr wb kak putra. saya tertarik dengan pelatihan *WOUNDCARE* mohon informasi lebih lanjut ya",
            direct_formulir: "https://daftar.intraina.id/"
        }
    },
    khitan: {
        webConfig: {
            header: {
                title: "Khitan (Batch 1) - Intraina",
            },
            body: {
                title: `
                    <span style="font-size: 0.8em; line-height: 0em !important">PELATIHAN KHITAN MODERN</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">SUNATLEM | SUNATCLAMP | STAPLER</span><br>
                    <span style="font-size: 0.5em; line-height: 0em !important">BATCH 1</span><br>
                `,
                titleSize: 2.6,
                description: "",
                date: "26 - 27 Februari 2022",
                dateDescription: `
                    <div style="margin-top: 10px;" />PELATIHAN TATAP MUKA<br>
                    <span style="font-weight: bold">Hotel Royal Park Samarinda</span>
                `,
                imageAfterTitle: "https://tebuireng.online/wp-content/uploads/2018/03/Khitan.png",
                purpose: [
                    "Setelah mengikuti pelatihan ini peserta mampu",
                    "1. Memahami konsep khitan modern",
                    "2. Memiliki keterampilan khitan modern",
                    "3. Melaksanakan praktek khitan modern",
                ],
                imageList: [
                    "https://cdn1-production-images-kly.akamaized.net/kma5mSsJNCsRG6fFcA0eTx3l3pc=/1200x900/smart/filters:quality(75):strip_icc():format(jpeg)/kly-media-production/medias/3718073/original/090146500_1639705444-khitan_alat2.jpg",
                ],
                // instruktur: `
                //     TIM GTC JAKARTA
                // `,
                // material: [
                //     "Konsep Dasar Perawatan Luka",
                //     "Konsep Nutrisi Dalam Penyembuhan Luka",
                //     "Wound Bed Preparation : TIME",
                //     "Pengkajian dan Konsep Manajemen Perawatan Luka",
                //     "Konsep BIOBURDEN dalam Perawatan Luka",
                //     "Askep Luka Akut : Post OP & Trauma",
                //     "Askep Luka Akut : Luka Bakar",
                //     "Askep Luka Kronik : Pressure Injury",
                //     "Askep Luka Kronik : DFU",
                //     "Askep Luka Kronik : Luka Kanker",
                //     "Praktek Klinik"
                // ],
                // imageAfterMaterial: "https://www.news-medical.net/image.axd?picture=2018%2F8%2Fshutterstock_367590767_-_wound.jpg",
                facility: [
                    "Sertifikat",
                    "T-Shirt",
                    "Training Kit",
                    "Modul Pelatihan",
                    "Bahan Praktek Khitan",
                    "Makan siang dan Coffee Break",
                ],
                registration: {
                    price: `
                        Rp 1.500.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(Harga Reguler)</span>
                        <hr style="border: 1px solid black">
                        Rp 1.300.000
                        <div style="margin-top: -40px" />
                        <span style="font-size: 1rem">(s.d 5 Feb 2022)</span>
                    `,
                    priceDescription: "Batas registrasi S.D 25 Februari 2022",
                    dateEnd: {
                        year: 2022,
                        month: 2,
                        day: 26,
                    }
                },
                dataAppend: [
                    {
                        title: "Syarat Peserta",
                        content: `
                        <ul style="list-style-type: decimal;" class="list-custom">
                            <li>
                                Dokter / Perawat / Bidan
                            </li>
                            <li>
                                Melampirkan Berkas :<br>
                                a. Fotocopy Ijazah Terakhir (Pendidikan Kesehatan)<br>
                                b. Pas foto 3x4 berwarna latar merah @2 lbr
                            </li>
                            <li>
                                Melakukan Booking seat dengan<br>
                                Registrasi senilai Rp. 500.000 transfer Ke mandiri no rek 1490023838990 a.n PT INTRAINA JAYA MANDIRI
                            </li>
                            <li>
                                Menunjukkan Bukti Vaksinasi Covid-19
                            </li>
                        </ul>
                        `
                    },
                ],
            }
        },
        registration: {
            direct_whatsapp: "https://api.whatsapp.com/send?phone=6285845727387&text=Assalamualaikum wr wb kak putra. saya tertarik dengan pelatihan *KHITAN* mohon informasi lebih lanjut ya",
            direct_formulir: "https://daftar.intraina.id/"
        }
    }
}